import React from 'react';
import SubscribtionPageContainer
    from "../../components/SubscribtionPage/SubscribtionPageContainer/SubscribtionPageContainer";
    import { Scrollbar } from 'react-scrollbars-custom';

function SubscribtionPage() {
    return (
        <Scrollbar style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <div className={'gradientBg'}>
                <div className="container">
                    <SubscribtionPageContainer />
                </div>
            </div>
        </Scrollbar>
    );

}

export default SubscribtionPage;