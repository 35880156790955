import { useEffect } from "react";
import { useSelector } from "react-redux";
import { subscriptionTypes } from "../constants";

export const useSocket = (onMessageReceived, onRequestData) => {
    const user = useSelector(state => state.auth.user)
    const autoRefresh = useSelector(state => state.arbitrage.autoRefresh)
    const token = useSelector(state => state.auth.token); 

    useEffect(() => {

        const socket = new WebSocket('wss://1whale.io/ws');

        if (user) {
            if (autoRefresh) {
                socket.addEventListener('open', (event) => {
                    const subscribeMessage = {
                        event: 'subscribe',
                        kind:'arb',
                        id: user.id,
                        token: token
                    };

                    socket.send(JSON.stringify(subscribeMessage));
                });

                socket.addEventListener('message', (event) => {
                    const responseData = JSON.parse(event.data);

                    if (responseData.data) {
                        console.log('data recived')
                        onMessageReceived(responseData.data);
                    }

                    if (responseData.id === -1) {
                        window.location.reload();
                    }
                });
                socket.addEventListener('close', (event) => {
                    console.dir('WebSocket connection closed:');
                });

                socket.addEventListener('error', (event) => {
                    console.error('WebSocket error:', event);
                });
            } else {
                onRequestData()
            }

            if (user.subscription === subscriptionTypes.arb && !autoRefresh) {
                socket.close();
            }

        }

        return () => {
            socket.close();
        };

    }, [user, autoRefresh]);
}