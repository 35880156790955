import React from 'react';
import styles from "./MainPageArbitrageBot.module.scss"
import { dexBotImage, dexBotImageWebp, dexBotMobImage } from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { useSelector } from "react-redux";


function MainPageArbitrageBot() {
    const token = useSelector(state => state.auth.token);
    const user = useSelector(state => state.auth.user)
    const socket = new WebSocket('wss://1whale.io/ws');

    function sendHeartbeat(socket) {
        socket.send('heartbeat');
    }

    socket.addEventListener('open', function (event) {
        const subscribeMessage = {
            event: 'heartbeat',
            id: user.id,
            token: token
        };

        socket.send(JSON.stringify(subscribeMessage));

    });

    socket.addEventListener('message', (event) => {
        const responseData = JSON.parse(event.data);

        // console.log('Получено сообщение от сервера:', event.data);

        if (responseData.id === -1) {
            console.log('Reload page');
            window.location.reload();
        }
    });

    socket.addEventListener('close', function (event) {
        // console.log('Соединение закрыто');
    });

    socket.addEventListener('error', function (event) {
        // console.error('Ошибка соединения:', event);
    });

    return (

        <div className={styles["arbitrageBot"]}>
            <h2 className={`${styles["arbitrageBot__title"]} titleTxt`}>
                <span className={'blueText'}>DEX    </span>
                Arbitrage Bot</h2>
            <p className={`${styles["arbitrageBot__contentText"]} contentTxt`}>Мониторинг цен по всем DEX обменникам, как внутри одной блокчейн сети, так и между другими блокчейн сетями.
                Ведется полный учет всех комиссий, мостов и объема торгов для извлечения большего профита.</p>
            <ImageWebp
                srcSet={dexBotImageWebp}
                src={dexBotImage}
                alt="Bot"
                className={`${styles["arbitrageBot__image"]} ${styles["arbitrageBot__image_desk"]}`}
            />
            <ImageWebp
                src={dexBotMobImage}
                alt="Bot"
                className={`${styles["arbitrageBot__image"]} ${styles["arbitrageBot__image_mob"]}`}
            />
        </div>
    );
}

export default MainPageArbitrageBot;