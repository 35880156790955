export const particlesOptions= {
    background: {
        color: {
            value: "#0A0C1A",
        },
    },
    fpsLimit: 120,
        interactivity: {
        events: {
            onClick: {
                enable: false,
                    mode: "push",
            },
            onHover: {
                enable: false,
                    mode: "repulse",
            },
            resize: true,
        },
        modes: {
            push: {
                quantity: 4,
            },
            repulse: {
                distance: 200,
                    duration: 0.4,
            },
        },
    },
    particles: {
        color: {
            value: "#d6d8ed",
        },
        links: {
            color: "#586cd2",
                distance: 150,
                enable: true,
                opacity: 0.4,
                width: 1,
        },
        move: {
            direction: "none",
                enable: true,
                outModes: {
            default: "bounce",
            },
            random: false,
                speed: 5,
                straight: false,
        },
        number: {
            density: {
                enable: true,
                    area: 800,
            },
            value: 100,
        },
        opacity: {
            value: 0.4,
        },
        shape: {
            type: "circle",
        },
        size: {
            value: { min: 0, max: 2 },
        },
    },
    detectRetina: true,
}
