import React from 'react';
import ArbitragePageHeader from "../../components/ArbitragePage/ArbitragePageHeader/ArbitragePageHeader";
import ArbitragePageList from "../../components/ArbitragePage/ArbitragePageList/ArbitragePageList";
import { useSelector } from "react-redux";
import { subscriptionTypes } from "../../constants";
import { Scrollbar } from 'react-scrollbars-custom';

function ArbitragePage() {
    const user = useSelector(state => state.auth.user)
    return (
        <Scrollbar style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div className={'gradientBg'}>
                <div className="container">
                    {user?.subscription === subscriptionTypes.arb ? <ArbitragePageHeader /> : null}
                    <ArbitragePageList />
                </div>
            </div>
        </Scrollbar>
    );
}

export default ArbitragePage;