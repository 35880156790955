import React from 'react';
import SettingsPageBlock from "../../components/SettingsPage/SettingsPageBlock/SettingsPageBlock";
import { Scrollbar } from 'react-scrollbars-custom';

function SettingsPage() {
    return (
        <Scrollbar style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <div className={'gradientBg'}>
                <div className="container">
                    <SettingsPageBlock />
                </div>
            </div>
        </Scrollbar>
    );
}

export default SettingsPage;