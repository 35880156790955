export const burgerIcon = "burgerIcon"
export const settingsIcon = 'settings'
export const subscriptionIcon = 'subscription'
export const arrowIcon = 'arrow'
export const logOutIcon = 'logOut'
export const telegramIcon = 'telegram'
export const youtubeIcon = 'youtube'
export const twitterIcon = 'twiter'
export const supportIcon = 'support'
export const arbitrageIcon = 'arbitrage'
export const newsIcon = 'news'
export const arrowDownIcon = 'arrowDown'
export const successIcon = 'success'
export const arrowWithLineIcon = 'arrowWithLine'
export const errorIcon = 'error'
export const crossIcon = 'cross'
export const emailIcon = 'email'
export const eyeIcon = 'eye'
export const clockIcon = 'clock'
export const refreshIcon = 'refresh'
export const emptyIcon = 'empty'
export const deleteIcon = 'delete'