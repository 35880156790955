import React from 'react';
import SupportPageBlock from "../../components/SupportPage/SupportPageBlock/SupportPageBlock";
import { Scrollbar } from 'react-scrollbars-custom';

function SupportPage() {
    return (

        <Scrollbar style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <div className={'gradientBg'}>
                <div className="container">
                    <SupportPageBlock />
                </div>
            </div>
        </Scrollbar>
    );
}

export default SupportPage;